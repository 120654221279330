import {
  Box,
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import React from "react";
import LazyLoad from "react-lazyload";
import { eventeerStyles } from "../../../../utils/hooks/useApplyStyles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import winnerImg2024 from "../../../../assets/Eventeer2025/Eventeer-25-Badge-Winner-2.png";
import runnerImg2024 from "../../../../assets/Eventeer2025/Eventeer-25-Badge-Runner-Up-2.png";

const WinnerGet = () => {
  const style = eventeerStyles();
  const classes = style();
  return (
    <Box className={classes.winnerget}>
      <Container maxLength="lg">
        <Box className={classes.EventeerWrap}>
          <Box className={classes.winnerHead}>
            <Typography
              variant="h2"
              align="center"
              className={classes.eventeerTitle}
            >
              What Winners Will Get
            </Typography>
            <Typography variant="body1">
              Winners will be announced on February 24, 2025.
            </Typography>
            <Typography variant="body1">
              Both winners and runners-up will receive a certificate of
              appreciation and Eventeer Award badges. You may use the badges
              online, in social media, in email signatures, and in print to
              publicize your achievement.
            </Typography>
          </Box>
          <Grid container spacing={4} justifyContent="center">
            <Grid item lg={6} xs={12}>
              <Box
                className={classes.winnerBox}
                style={{
                  background:
                    "linear-gradient(180deg, #F96634 0%, #EF4678 100%)",
                }}
              >
                <Typography variant="h4" component="h2">
                  Winners
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Spotlight in the Eventeer Awards winners campaign." />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Trophies, badges, and certificates of appreciation." />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Invitation to the winner's roundtable hosted by vFairs CEO, Muhammad Younas." />
                  </ListItem>
                </List>
                <Box className={classes.winnerImg}>
                  <LazyLoad height={193}>
                    <img src={winnerImg2024} alt="Winners Badge" />
                  </LazyLoad>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Box
                className={classes.winnerBox}
                style={{ background: "#F8A72E" }}
              >
                <Typography variant="h4" component="h2">
                  Runners-Up
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Certificate of Appreciation" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Digital Badge" />
                  </ListItem>
                </List>
                <Box className={classes.winnerImg}>
                  <LazyLoad height={193}>
                    <img src={runnerImg2024} alt="Runners-Up" />
                  </LazyLoad>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default WinnerGet;
