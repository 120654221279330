import { Box, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import { eventeerStyles } from "../../../../utils/hooks/useApplyStyles";
import { Link } from "react-router-dom";
import LoaderLines from "../../../Loader/LoaderLines";

const NomineeCategories = ({ allCollections, postFilters, setPostFilters }) => {
  const style = eventeerStyles();
  const classes = style();
  return (
    <Box className={classes.nomineeCategories}>
      <Typography variant="h3" component="h2">
        All Categories
      </Typography>
      <List>
        {allCollections.data &&
          allCollections.isLoading === false &&
          allCollections.data.total_count &&
          allCollections.data.total_count > 0 &&
          allCollections.data.data &&
          allCollections.data.data.map(
            (collection) =>
              collection.status &&
              collection.status === "1" &&
              collection.is_private &&
              collection.is_private === "0" && (
                <ListItem>
                  <Link
                    to={
                      "/eventeer-awards/" +
                      collection.id +
                      "/" +
                      collection.slug
                    }
                    id={collection.id}
                    className={
                      postFilters.categoryId === collection.id ? "active" : ""
                    }
                    onClick={() =>
                      setPostFilters({
                        ...postFilters,
                        categoryId: collection.id,
                        categoryName: collection.name,
                      })
                    }
                  >
                    {collection.name}
                  </Link>
                </ListItem>
              )
          )}
      </List>
      {allCollections && allCollections.isLoading && (
        <List>
          <ListItem>
            <LoaderLines />
          </ListItem>
          <ListItem>
            <LoaderLines />
          </ListItem>
          <ListItem>
            <LoaderLines />
          </ListItem>
          <ListItem>
            <LoaderLines />
          </ListItem>
          <ListItem>
            <LoaderLines />
          </ListItem>
          <ListItem>
            <LoaderLines />
          </ListItem>
          <ListItem>
            <LoaderLines />
          </ListItem>
          <ListItem>
            <LoaderLines />
          </ListItem>
          <ListItem>
            <LoaderLines />
          </ListItem>
        </List>
      )}
    </Box>
  );
};

export default NomineeCategories;
