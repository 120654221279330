import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { eventeerStyles } from "../../../../utils/hooks/useApplyStyles";
import NomineeCategories from "./NomineeCategories";
import LoaderCard from "../../../Loader/LoaderCard";
import NomineeCard from "./NomineeCard";

const NomineeWrap = () => {
  const style = eventeerStyles();
  const classes = style();

  const allCategoriesBaseURL = `${global.API.rootPath}${global.config.endpoints.collections_endpoint}?access_key=${global.config.access_key}&is_eventeer_award=1&limit=200`;
  const [allCollections, setAllCollections] = useState({
    data: null,
    isLoading: false,
    error: "",
  });
  //fetch all eventeer collections
  useEffect(() => {
    setAllCollections({ ...allCollections, isLoading: true });
    axios
      .get(allCategoriesBaseURL)
      .then((response) => {
        setAllCollections({
          ...allCollections,
          data: response.data,
          isLoading: false,
        });
      })
      .catch((err) =>
        setAllCollections({ ...allCollections, error: err, isLoading: false })
      );
  }, []);

  const { id, nominee_slug } = useParams();
  const getCollectionURL = `${global.API.rootPath}${global.config.endpoints.collections_endpoint}?access_key=${global.config.access_key}&is_eventeer_award=1&limit=1&collection_id=${id}`;

  const [postFilters, setPostFilters] = useState({
    categoryId: id,
    categoryName: "",
  });

  //for redirection to correct slug
  //   if (
  //     post &&
  //     post.data &&
  //     post.data.total_count &&
  //     post.data.total_count !== 0 &&
  //     post.data.data[0] &&
  //     post.data.data[0].app_title &&
  //     post.data.data[0].application &&
  //     post.data.data[0].application.is_published_on_discover
  //   ) {
  //     if (post.data.data[0].application.is_published_on_discover === "0") {
  //       window.location.href = "/";
  //     } else {
  //       let fetched_slug = post.data.data[0].slug
  //         ? post.data.data[0].slug
  //         : post.data.data[0].app_title
  //             .toLowerCase()
  //             .replace(/ /g, "-")
  //             .replace(/[^\w-]+/g, "");
  //       if (event_slug !== fetched_slug) {
  //         window.location = fetched_slug;
  //       }
  //     }
  //   }

  const [nominee, setnominee] = useState({
    data: null,
    isLoading: false,
    error: "",
  });

  useEffect(() => {
    setnominee({ ...nominee, isLoading: true });
    axios
      .get(getCollectionURL)
      .then((response) => {
        setnominee({ ...nominee, data: response.data, isLoading: false });
      })
      .catch((err) => setnominee({ ...nominee, error: err, isLoading: false }));
  }, [postFilters.categoryId]);

  const GET_EVENT_API = `${global.API.rootPath}${global.config.endpoints.events_endpoint}?access_key=${global.config.access_key}&page=1&limit=1`;

  const [collectionEvents, setCollectionEvents] = useState({
    data: {},
    isLoading: false,
  });
  useEffect(() => {
    if (
      nominee.data &&
      nominee.data.total_count &&
      nominee.data.total_count > 0 &&
      nominee.data.data[0] &&
      nominee.data.data[0].status &&
      nominee.data.data[0].status === "1" &&
      nominee.data.data[0].is_private &&
      nominee.data.data[0].is_private === "0"
    ) {
      if (nominee.data.data[0].event_id) {
        var event_ids = nominee.data.data[0].event_id.split(",");
        setCollectionEvents({ ...collectionEvents, isLoading: true });
        axios
          .all(
            event_ids.map((ids) =>
              axios.get(GET_EVENT_API + `&event_id=${ids}`)
            )
          )
          .then((data) =>
            setCollectionEvents({
              ...collectionEvents,
              data: data,
              isLoading: false,
            })
          );
      } else {
        setCollectionEvents({
          ...collectionEvents,
          data: "No events found",
          isLoading: false,
        });
      }
    }
  }, [nominee.data]);
  return (
    <Box className={classes.nominee}>
      <Container maxLength="lg">
        <div style={{ minHeight: 10 }}>
          {collectionEvents.isLoading && <LinearProgress />}
        </div>
        <Grid container spacing={3} className={classes.nomineeRow}>
          <Grid item lg={3}>
            <NomineeCategories
              allCollections={allCollections}
              postFilters={postFilters}
              setPostFilters={setPostFilters}
            />
          </Grid>
          <Grid item lg={9}>
            <Box className={classes.nomineeMai}>
              <Typography variant="h2">
                Nominees For{" "}
                {nominee &&
                  nominee.data &&
                  nominee.data.data[0] &&
                  nominee.data.data[0].name}
              </Typography>
              <Grid container spacing={3} className={classes.nomineeRow}>
                {collectionEvents &&
                !collectionEvents.isLoading &&
                collectionEvents.data &&
                collectionEvents.data.length > 0 &&
                collectionEvents.data !== "No events found" ? (
                  collectionEvents.data.map(
                    (event) =>
                      event &&
                      event.data &&
                      event.data.data &&
                      event.data.data[0] &&
                      event.data.data[0].app_id &&
                      event.data.data[0].app_title &&
                      event.data.data[0].application &&
                      event.data.data[0].application.is_published_on_discover &&
                      event.data.data[0].application
                        .is_published_on_discover === "1" && (
                        <Grid item md={6}>
                          <NomineeCard
                            event_data={event.data.data[0]}
                            collection_id={id}
                          />
                        </Grid>
                      )
                  )
                ) : collectionEvents &&
                  !collectionEvents.isLoading &&
                  collectionEvents.data &&
                  collectionEvents.data == "No events found" ? (
                  <Grid item md={12}>
                    <Typography variant="h4">
                      Currently, there are no nominees in this category.
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    <Grid item md={6}>
                      <LoaderCard />
                    </Grid>
                    <Grid item md={6}>
                      <LoaderCard />
                    </Grid>
                    <Grid item md={6}>
                      <LoaderCard />
                    </Grid>
                    <Grid item md={6}>
                      <LoaderCard />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NomineeWrap;
