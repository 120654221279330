import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Container, Grid, Typography, Button } from "@material-ui/core";
import { eventeerStyles } from "../../../../utils/hooks/useApplyStyles";
import eveenteerplaceholder from "../../../../assets/images/eveenteer-category-placeholder.png";
import LoaderCard from "../../../Loader/LoaderCard";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const DiscoverCategories = ({ submitVotesRef }) => {
  const [catFilters, setCatFilters] = useState({
    limit: 30,
  });
  const collectionsBaseURL = `${global.API.rootPath}${global.config.endpoints.collections_endpoint}?access_key=${global.config.access_key}&limit=50&is_eventeer_award=1`;
  const [collections, setCollections] = useState({
    data: null,
    isLoading: false,
    error: "",
  });
  //fetch collections
  useEffect(() => {
    setCollections({ ...collections, isLoading: true });
    axios
      .get(collectionsBaseURL)
      .then((response) => {
        setCollections({
          ...collections,
          data: response.data,
          isLoading: false,
        });
      })
      .catch((err) =>
        setCollections({ ...collections, error: err, isLoading: false })
      );
  }, [catFilters]);

  const style = eventeerStyles();
  const classes = style();
  return (
    <Box className={classes.eventCategories} ref={submitVotesRef}>
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          className={classes.eventeerTitle}
        >
          Discover Voting Categories
        </Typography>
        <Typography
          variant="body1"
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "center",
            marginBottom: "45px",
          }}
        >
          Vote for your Favorite Event. See Categories below!
        </Typography>
        {/* <Typography
          variant="body1"
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "center",
            marginBottom: "45px",
          }}
        >
          {" "}
          See Categories below!
        </Typography> */}
        <Grid container spacing={2}>
          {collections && collections.isLoading && (
            <>
              <Grid item lg={3} md={4} sm={6}>
                <LoaderCard />
              </Grid>
              <Grid item lg={3} md={4} sm={6}>
                <LoaderCard />
              </Grid>
              <Grid item lg={3} md={4} sm={6}>
                <LoaderCard />
              </Grid>
              <Grid item lg={3} md={4} sm={6}>
                <LoaderCard />
              </Grid>
              <Grid item lg={3} md={4} sm={6}>
                <LoaderCard />
              </Grid>
              <Grid item lg={3} md={4} sm={6}>
                <LoaderCard />
              </Grid>
              <Grid item lg={3} md={4} sm={6}>
                <LoaderCard />
              </Grid>
              <Grid item lg={3} md={4} sm={6}>
                <LoaderCard />
              </Grid>
            </>
          )}
          {collections.data &&
            collections.isLoading === false &&
            collections.data.total_count &&
            collections.data.total_count > 0 &&
            collections.data.data &&
            collections.data.data.map(
              (collection) =>
                collection.status &&
                collection.status === "1" &&
                collection.is_private &&
                collection.is_private === "0" && (
                  <Grid
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    className={classes.categoryBoxCardMain}
                  >
                    <Box className={classes.categoryBox}>
                      <Box className={classes.categoryImage}>
                        <LazyLoad height={370}>
                          <img
                            src={
                              collection.thumbnail
                                ? collection.thumbnail
                                : collection.cover_image
                                ? collection.cover_image
                                : eveenteerplaceholder
                            }
                            alt={collection.name}
                          />
                        </LazyLoad>
                      </Box>
                      <Box className={classes.categorybtn}>
                        <Link title={collection.name}>{collection.name}</Link>
                      </Box>
                      <Box className={classes.categorymore}>
                        <div>
                          <Typography variant="h5" component="h2">
                            {collection.name}
                          </Typography>
                          <Typography variant="body1">
                            {collection.tag_line}
                          </Typography>
                        </div>
                        <Box className={classes.categoryreadmore}>
                          <Link
                            title={collection.name}
                            to={
                              "/eventeer-awards/" +
                              collection.id +
                              "/" +
                              collection.slug
                            }
                          >
                            See Nominees <ArrowForwardIcon />
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                )
            )}
        </Grid>
        {catFilters && catFilters.limit && catFilters.limit === 8 && (
          <Box className={classes.loadMore}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                setCatFilters({
                  ...catFilters,
                  limit: 100,
                })
              }
            >
              Load More
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default DiscoverCategories;
