import { Box, Container, Typography, Grid, Button } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { homeBannerStyles } from "../../utils/hooks/useApplyStyles";
//import eventeerLogo from '../../assets/Eventeer2025/Eventeerlogo-1.svg';
import moment from "moment";

const BannerEventeerNomination = ({ submitVotes }) => {
  const styles = homeBannerStyles();
  const classes = styles();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    setInterval(() => {
      const now = moment().toDate();
      const time = Date.parse("2025-02-17") - Date.parse(now);
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));
      setDays(days < 0 ? "0" : days);
      setHours(hours < 0 ? hours * -1 : hours);
      setMinutes(minutes < 0 ? minutes * -1 : minutes);
      setSeconds(seconds < 0 ? seconds * -1 : seconds);
    }, 1000);
  }, []);
  return (
    <>
      <Box
        className={`${classes.bannerEventeevs} ${classes.bannerEventeerNom}`}
      >
        <Container maxWidth="lg">
          <Grid container alignItems="center">
            <Grid item xs={12} lg={6}>
              <div className={classes.bannerHomeContentEventeer}>
                <Typography
                  variant="h1"
                  component="h1"
                  gutterBottom
                  className={`${classes.mainText} ${classes.eventtitle}`}
                >
                  Where Epic Events Take Spotlight
                </Typography>
                <Typography variant="p" component="p" gutterBottom>
                  Eventeer Awards honor the most innovative and successful
                  events our customers hosted using vFairs in 2024. We aim to
                  have your hard work recognized by the world.
                </Typography>
                <Box className={classes.bannerEvneerBtns}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitVotes}
                  >
                    Vote for Your Favorite Event
                  </Button>
                  <a
                    href="/eventeer-awards-winners"
                    className={classes.winnersCTA}
                  >
                    See Past Winners
                    <ArrowForwardIcon />{" "}
                  </a>
                </Box>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              align="center"
              className={classes.eventeerVideo}
            >
              <iframe
                src="https://www.youtube.com/embed/-VIJGzZbhB4?si=Euj-34ANPQ-BT7hx"
                title="Recognizing Event Brilliance With Your Moments and Our Awards"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.TimerSection}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" component="h6">
                Votings are Open Now!
              </Typography>
              <Typography variant="body1">
                Voting will close on 17 February 2025. Get votes before time
                runs out.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={3}>
                  <div className={classes.eventeerCBox}>
                    <span className="number">{days}</span>
                    <span>Days</span>
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className={classes.eventeerCBox}>
                    <span className="number">{hours}</span>
                    <span>Hrs</span>
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className={classes.eventeerCBox}>
                    <span className="number">{minutes}</span>
                    <span>Mins</span>
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className={classes.eventeerCBox}>
                    <span className="number">{seconds}</span>
                    <span>Seconds</span>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default BannerEventeerNomination;
